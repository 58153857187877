import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromGigSheets from '../reducers/gigsheet.reducers';
import { DateTime } from 'luxon';
import { Timestamp } from '@angular/fire/firestore';

export const getRootGigsheetsState = (state: fromRoot.GlobalAppState) => state.gigsheets;
export const getAllGigSheets = createSelector(getRootGigsheetsState, fromGigSheets.selectAllGigSheet);
export const selectGigSheetsLoading = createSelector(getRootGigsheetsState, fromGigSheets.getGigSheetsLoading);
export const selectGigSheetSaving = createSelector(getRootGigsheetsState, fromGigSheets.getGigSheetSaving);
export const getSingleGigSheet = (id: string) => createSelector(getAllGigSheets, (allGigSheets) => {
    if(allGigSheets) {
        return allGigSheets.filter(gigSheet => {
            return gigSheet.gigsheet_id === id;
        })[0];
    } else {
        return null;
    }
});

export const getUpcomingGigSheets = createSelector(
  getAllGigSheets,
  (allGigSheets) => {
    const now = DateTime.fromJSDate(new Date()).toMillis();
    return allGigSheets
      ?.filter(gigSheet => {
        const eventTimestamp = gigSheet.event_date_timestamp as Timestamp;
        return eventTimestamp.toMillis() > now;
      })
      .sort((a, b) => {
        const aTime = (a.event_date_timestamp as Timestamp).toMillis();
        const bTime = (b.event_date_timestamp as Timestamp).toMillis();
        return bTime - aTime;
      }) || [];
  }
);

export const getPastGigSheets = createSelector(
    getAllGigSheets,
    (allGigSheets) => {
      const now = DateTime.fromJSDate(new Date()).toMillis();
      return allGigSheets
      ?.filter(gigSheet => {
        const eventTimestamp = gigSheet.event_date_timestamp as Timestamp;
        return eventTimestamp.toMillis() < now;
      })
      .sort((a, b) => {
        const aTime = (a.event_date_timestamp as Timestamp).toMillis();
        const bTime = (b.event_date_timestamp as Timestamp).toMillis();
        return bTime - aTime;
      }) || [];
    }
);
